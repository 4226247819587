.price-monitor {
    padding: 20px;
    width: 100%;
}

.top-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #8e44ad;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.action-button:hover {
    background-color: #9b59b6;
}

.notifications-container {
    margin-top: 20px;
}

.notifications-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.notifications-table th,
.notifications-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #444;
}

.notifications-table th {
    background-color: #2c2c2c;
    color: white;
    font-weight: bold;
}

.notifications-table tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.action-icons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.action-icons a {
    color: #8e44ad;
    transition: color 0.3s;
}

.action-icons a:hover {
    color: #9b59b6;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button[type="submit"] {
    background-color: #8e44ad;
    color: white;
}

.modal-buttons button[type="button"] {
    background-color: #6c757d;
    color: white;
}

.tasks-list {
    max-height: 400px;
    overflow-y: auto;
}

.task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.task-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.error-message {
    background-color: #dc3545;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

/* Dark mode styles */
.dark-mode .modal-content {
    background-color: #2c2c2c;
    color: white;
}

.dark-mode .form-group input {
    background-color: #3c3c3c;
    border-color: #444;
    color: white;
}

.dark-mode .task-item {
    border-bottom-color: #444;
}

.dark-mode .notifications-table th {
    background-color: #3c3c3c;
}

.dark-mode .notifications-table td {
    border-bottom-color: #444;
}


.price-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.source-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Add this to your PriceMonitor.css file */
select option {
    padding: 8px;
    min-height: 1.2em;
    display: flex;
    align-items: center;
  }

/* Ensure proper spacing between text and flag */
select option::after {
    content: " ";
    white-space: pre;
  }  