.app {
  display: flex;
  height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

.light-mode {
  background-color: #f0f0f0; /* Light grey background for light mode */
  color: #000000;
}

.dark-mode {
  background-color: #1a1a1a;
  color: white;
}


.sidebar {
  width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  flex-shrink: 0;
  position: relative;
}

.sidebar.collapsed {
  width: 50px;
  padding: 20px 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-toggle {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.sidebar.collapsed .sidebar-toggle {
  right: 50%;
  transform: translateX(50%);
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}

.sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
  min-width: 20px;
}

.sidebar-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar.collapsed .sidebar-text {
  display: none;
}

.sidebar-menu-item:hover {
  background-color: #9b59b6; /* Lighter purple for hover */
}

.sidebar-menu-item.active {
  background-color: #8e44ad; /* Purple color */
  color: white;
}

.dark-mode .sidebar {
  background-color: #343a40;
  color: #f8f9fa;
}

.dark-mode .sidebar-toggle {
  color: #f8f9fa;
}

.dark-mode .sidebar-menu-item:hover {
  background-color: #7d3c98; /* Slightly lighter purple for hover in dark mode */
}

.dark-mode .sidebar-menu-item.active {
  background-color: #6c3483; /* Darker purple for dark mode */
}

/* Adjust main content when sidebar is collapsed */
.sidebar.collapsed + .main-content {
  margin-left: 50px;
  width: calc(100% - 50px);
  transition: margin-left 0.3s ease, width 0.3s ease;
}

/* Existing styles */
.main-content {
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
  overflow-x: hidden;
  margin-left: 0; /* Remove the left margin */
  width: calc(100% - 250px); /* Adjust width based on sidebar width */
}

.sidebar.collapsed + .main-content {
  width: calc(100% - 50px); /* Adjust width when sidebar is collapsed */
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .sidebar.collapsed {
    width: 50px;
  }

  .main-content {
    width: calc(100% - 200px);
  }

  .sidebar.collapsed + .main-content {
    width: calc(100% - 50px);
  }
}

.light-mode .main-content {
  background-color: #f0f0f0; /* Light grey background for main content in light mode */
}

.dark-mode .main-content {
  background-color: #1a1a1a; /* Keep dark background for main content in dark mode */
}

/* Update filter bar styles for light/dark mode */
.filter-bar {
  background-color: #f3f4f6; /* Light grey for light mode */
  border: 1px solid #e5e7eb;
  padding: 16px;
  border-radius: 8px;
}

.filter-bar select,
.filter-bar input {
  background-color: #ffffff; /* White background for light mode */
  border: 1px solid #e5e7eb;
  color: #000000;
}

/* Dark mode styles */
.dark-mode .filter-bar {
  background-color: #2c2c2c; /* Dark grey for dark mode */
  border-color: #404040;
}

.dark-mode .filter-bar select,
.dark-mode .filter-bar input {
  background-color: #3c3c3c; /* Lighter grey for dark mode */
  border-color: #404040;
  color: #ffffff;
}

/* Hover states */
.filter-bar select:hover,
.filter-bar input:hover {
  border-color: #d1d5db; /* Light mode hover */
}

.dark-mode .filter-bar select:hover,
.dark-mode .filter-bar input:hover {
  border-color: #6b7280; /* Dark mode hover */
}

/* Placeholder colors */
.filter-bar input::placeholder {
  color: #9ca3af; /* Light mode placeholder */
}

.dark-mode .filter-bar input::placeholder {
  color: #6b7280; /* Dark mode placeholder */
}

.filter-bar select[name="category"] {
  flex: 1.5;
  min-width: 150px;
}

.filter-bar input[type="number"] {
  flex: 1;
  min-width: 100px;
}

.filter-bar input[type="text"] {
  flex: 2;
  min-width: 200px;
}

.filter-bar input::placeholder {
  color: #888;
}

/* Light mode styles */
.light-mode .filter-bar {
  background-color: #f3f4f6;
  border-color: #e5e7eb;
}

.light-mode .filter-bar input,
.light-mode .filter-bar select {
  background-color: white;
  color: black;
  border: 1px solid #e5e7eb;
}

.light-mode .filter-bar input::placeholder {
  color: #888888;
}

.filter-bar button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.filter-bar button:hover {
  background-color: #45a049;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
}

.pagination {
  margin-top: 20px;
}

.pagination button {
  margin-right: 5px;
}

.error {
  color: red;
}

.mode-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dark-mode .mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.light-mode .mode-toggle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dashboard {
  padding: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
}

.square-card {
  aspect-ratio: 1 / 1;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.square-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #f0f0f0;
}

.light-mode .square-card {
  background-color: #ffffff;
}

.dark-mode .square-card {
  background-color: #2c2c2c;
}

.square-card h3 {
  margin: 0 0 10px 0;
  font-size: 1em;
}

.square-card p {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.a2a-database {
  padding: 20px;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.results-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.result-link-bar {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.link-button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.link-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.result-title {
  margin: 0 0 15px 0;
  font-size: 1.2em;
}

.result-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.product-image {
  flex: 0 0 120px; /* Forces image to maintain width and not shrink/grow */
  margin-right: 10px; /* Additional spacing to the right of image */
}

.result-details, .result-extra {
  flex: 1;
}

.result-details p, .result-extra p {
  margin: 5px 0;
}

.result-right {
  flex: 1;
  padding-left: 20px;
}

.result-right p {
  margin: 5px 0;
}

/* Dark mode styles */
.dark-mode .result-card {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* Light mode styles */
.light-mode .result-card {
  background-color: #f0f0f0;
  color: #000000;
}

.status-shipped {
  color: #4caf50;
}

.status-pending {
  color: #ffa000;
}

/* Add more status colors as needed */

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #4a4a4a;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.light-mode .pagination button {
  background-color: #d0d0d0;
  color: #333;
}

.pagination button:hover {
  opacity: 0.8;
}

/* Align right side content to the right */
.result-right {
  text-align: right;
}

/* Style for the timestamp */
.result-right p:last-child {
  margin-top: 15px;
}

.filter-section {
  background-color: #3a3a3a;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-section select,
.filter-section input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #2c2c2c;
  color: #fff;
}

.filter-section button {
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-section button:hover {
  background-color: #45a049;
}

.result-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.result-title {
  margin: 0;
  font-size: 1.2em;
}

.sas-link {
  display: flex;
  align-items: center;
}

.sas-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.result-content {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.result-details {
  flex: 1;
}

.result-details p {
  margin: 5px 0;
}

.result-additional {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.result-additional p {
  margin: 5px 0;
}

/* Dark mode styles */
.dark-mode .result-card {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* Light mode styles */
.light-mode .result-card {
  background-color: #f0f0f0;
  color: #000000;
}

.result-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.result-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.result-title {
  margin: 0;
  font-size: 1.2em;
}

.sas-link {
  display: flex;
  align-items: center;
}

.sas-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.result-content {
  display: flex;
  gap: 20px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.result-details {
  flex: 1;
}

.result-details p, .result-additional p {
  margin: 5px 0;
}

.result-additional {
  text-align: left;
  min-width: 200px; /* Adjust as needed */
}

/* Dark mode styles */
.dark-mode .result-card {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* Light mode styles */
.light-mode .result-card {
  background-color: #f0f0f0;
  color: #000000;
}

.filter-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5; /* Very light grey */
  padding: 1rem;
  border-radius: 0.5rem;
}

.filter-container select,
.filter-container input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
}

.filter-container select {
  min-width: 150px;
}

.filter-container input {
  width: 120px;
}

/* Add these styles for better visibility of placeholder text */
.filter-container input::placeholder {
  color: #aaa;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  background-color: #1e1e1e; /* Dark background for the entire table */
}

.results-table th,
.results-table td {
  padding: 8px;
  text-align: left;
  border: none; /* Remove all borders */
}

.results-table th {
  background-color: #2c2c2c; /* Slightly lighter background for headers */
  color: #ffffff;
  font-weight: bold;
}

.results-table tr {
  background-color: #1e1e1e; /* Dark background for rows */
}

.results-table tr:nth-child(even) {
  background-color: #252525; /* Slightly lighter background for even rows */
}

.results-table tr:hover {
  background-color: #2a2a2a; /* Highlight color on hover */
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.sas-icon {
  width: 16px;
  height: 16px;
}

/* Ensure text is white for better visibility on dark background */
.results-table {
  color: #ffffff;
}

/* Style for the timestamp column to match the image */
.results-table td:first-child {
  color: #888888; /* Grey color for timestamp */
}

.top-filter-bar,
.bottom-filter-bar {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #2c2c2c;
  border-radius: 4px;
  margin-bottom: 10px;
}

.top-filter-bar input,
.bottom-filter-bar input,
.bottom-filter-bar select {
  background-color: #3c3c3c;
  border: none;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  flex-grow: 1;
}

.top-filter-bar .amazon-search {
  flex-grow: 2;
}

.top-filter-bar input::placeholder,
.bottom-filter-bar input::placeholder,
.bottom-filter-bar select {
  color: #888;
}

.top-filter-bar button,
.bottom-filter-bar button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.top-filter-bar button:hover,
.bottom-filter-bar button:hover {
  background-color: #45a049;
}

.action-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.action-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: #ffffff;
}

.action-icon:hover {
  opacity: 0.8;
}



/* You may need to adjust other component styles to work well in both light and dark modes */

.light-mode .filter-bar,
.light-mode .top-filter-bar,
.light-mode .bottom-filter-bar {
  background-color: #e0e0e0;
}

.light-mode .filter-bar input,
.light-mode .filter-bar select,
.light-mode .top-filter-bar input,
.light-mode .bottom-filter-bar input,
.light-mode .bottom-filter-bar select {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
}

.light-mode .filter-bar input::placeholder,
.light-mode .top-filter-bar input::placeholder,
.light-mode .bottom-filter-bar input::placeholder {
  color: #888888;
}

/* Update results table styles for light mode */
.light-mode .results-table {
  background-color: #ffffff;
  color: #000000;
}

.light-mode .results-table th {
  background-color: #e0e0e0;
  color: #000000;
}

.light-mode .results-table tr {
  background-color: #ffffff;
}

.light-mode .results-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.light-mode .results-table tr:hover {
  background-color: #e8e8e8;
}

/* Update action icons color for light mode */
.light-mode .action-icon {
  color: #000000;
}

/* Add these styles at the end of the file */

.results-table th {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.results-table th svg {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.light-mode .results-table th:hover {
  background-color: #d0d0d0;
}

.dark-mode .results-table th:hover {
  background-color: #3c3c3c;
}

@media (max-width: 768px) {
  .square-card {
    width: calc(25% - 15px);
    height: auto;
  }

  .sidebar {
    width: 200px;
  }

  .sidebar.collapsed {
    width: 60px;
  }

  .main-content {
    padding: 10px;
  }

  .filter-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-bar input,
  .filter-bar select {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .square-card {
    width: calc(50% - 10px);
  }
}

.dark-mode .square-card:hover {
  background-color: #3c3c3c;
}

.a2a-notifications {
  padding: 20px;
}

.a2a-notifications .top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.a2a-notifications h2 {
  margin: 0;
}

.a2a-notifications .task-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #8e44ad; /* Purple color */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.a2a-notifications .task-button:hover {
  background-color: #9b59b6; /* Lighter purple for hover */
}

.a2a-notifications .results-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 0.9em;
}

.a2a-notifications .results-table th,
.a2a-notifications .results-table td {
  padding: 8px 4px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.a2a-notifications .results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.light-mode .a2a-notifications .results-table th {
  background-color: #e0e0e0;
}

.dark-mode .a2a-notifications .results-table th {
  background-color: #2c2c2c;
}

.a2a-notifications .delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.a2a-notifications .delete-button:hover {
  background-color: #d32f2f;
}

/* Ensure consistent styling in dark mode */
.dark-mode .a2a-notifications .results-table {
  color: #ffffff;
}

.dark-mode .a2a-notifications .results-table th {
  background-color: #2c2c2c;
}

.dark-mode .a2a-notifications .results-table td {
  border-bottom: 1px solid #444;
}

.notification-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin-bottom: 20px;
}

.notification-form select,
.notification-form input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.notification-form button {
  background-color: #8e44ad; /* Purple color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
}

.notification-form button:hover {
  background-color: #9b59b6; /* Lighter purple for hover */
}

/* For dark mode */
.dark-mode .notification-form button {
  background-color: #6c3483; /* Darker purple for dark mode */
}

.dark-mode .notification-form button:hover {
  background-color: #7d3c98; /* Slightly lighter purple for hover in dark mode */
}

.notifications-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.notification-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.notification-card p {
  margin: 5px 0;
}

.dark-mode .notification-card {
  background-color: #2c2c2c;
  color: #ffffff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.dark-mode .modal-content {
  background-color: #2c2c2c;
  color: #fff;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.dark-mode .close-modal {
  color: #fff;
}

.notification-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notification-form select,
.notification-form input,
.notification-form button {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.notification-form button {
  background-color: #8e44ad; /* Purple color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notification-form button:hover {
  background-color: #9b59b6; /* Lighter purple for hover */
}

/* For dark mode */
.dark-mode .notification-form button {
  background-color: #6c3483; /* Darker purple for dark mode */
}

.dark-mode .notification-form button:hover {
  background-color: #7d3c98; /* Slightly lighter purple for hover in dark mode */
}

.notification-checkbox {
  margin-right: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.notification-checkbox:checked {
  accent-color: #4CAF50;
}

/* Optional: Style checked rows differently */
tr.checked-notification {
  background-color: rgba(76, 175, 80, 0.1);
}

.filter-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  width: 100%;
  justify-content: flex-end;
}

.filter-button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.filter-button:hover {
  background: #f0f0f0;
}

.filter-button.active {
  background: #6b46c1;
  color: white;
  border-color: #6b46c1;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.button-group {
  display: flex;
  flex-direction: row;  /* Ensure horizontal alignment */
  align-items: center;
  gap: 8px;            /* Space between buttons */
  margin-top: 16px;    /* Add some spacing from the top */
}

.task-button {
  white-space: nowrap;
  padding: 8px 16px;
  border-radius: 4px;
  background: #6b46c1;  /* Purple background */
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.task-button:hover {
  background: #553c9a;  /* Slightly darker on hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Darker overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px); /* Blurs the background */
}

.purchase-modal {
  background-color: #ffffff; /* Light mode background */
  border-radius: 12px;
  padding: 25px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Dark mode support */
.dark-mode .purchase-modal {
  background-color: #2a2a2a;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eee;
}

.dark-mode .modal-header {
  border-bottom: 2px solid #333;
}

.modal-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.product-details {
  display: flex;
  gap: 30px; /* Increased gap between image and info */
  margin-bottom: 25px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
  align-items: flex-start; /* Aligns items to top */
}

.dark-mode .product-details {
  background-color: #333;
}

.product-image img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
}

.product-info {
  flex: 1;
}

.product-info h3 {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #444;
}

.dark-mode .form-group label {
  color: #ccc;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 1.1rem;
  transition: border-color 0.2s;
}

.form-group input:focus {
  border-color: #4a90e2;
  outline: none;
}

.dark-mode .form-group input {
  background-color: #333;
  border-color: #444;
  color: white;
}

.purchase-summary {
  margin: 25px 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #4a90e2;
}

.dark-mode .purchase-summary {
  background-color: #333;
  border-left: 4px solid #4a90e2;
}

.purchase-summary h3 {
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 15px;
}

.purchase-summary .total {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #eee;
  font-size: 1.3em;
  color: #4a90e2;
  font-weight: bold;
}

.dark-mode .purchase-summary .total {
  border-top: 2px solid #444;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 25px;
}

.cancel-button,
.confirm-button {
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s;
}

.cancel-button {
  background-color: #f0f0f0;
  border: none;
  color: #666;
}

.confirm-button {
  background-color: #4a90e2;
  border: none;
  color: white;
}

.dark-mode .cancel-button {
  background-color: #333;
  color: #ccc;
}

.dark-mode .confirm-button {
  background-color: #4a90e2;
}

.cancel-button:hover {
  background-color: #e4e4e4;
}

.confirm-button:hover {
  background-color: #357abd;
}

.dark-mode .cancel-button:hover {
  background-color: #444;
}

.dark-mode .confirm-button:hover {
  background-color: #357abd;
}

/* Add visual feedback for clickable elements */
.cancel-button:active,
.confirm-button:active {
  transform: scale(0.98);
}

/* Improve readability of product details */
.product-info p {
  margin: 8px 0;
  line-height: 1.4;
}

.product-info strong {
  display: inline-block;
  min-width: 100px;
  color: #666;
}

.dark-mode .product-info strong {
  color: #aaa;
}

/* Adjust for collapsed sidebar */
.sidebar.collapsed .footer-buttons {
  justify-content: center;
}

.sidebar.collapsed .logout-button .sidebar-text {
  display: none;
}

.sidebar.collapsed .dark-mode-toggle {
  padding: 10px 0;
}

/* Original Cards Styling */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background-color: var(--background-color-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-color-secondary);
}

.stat-card .stat-value {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
  color: var(--text-color);
}

/* Circular Stats Styling */
.detailed-stats {
  margin-top: 3rem;
}

.stat-section {
  background: var(--background-color-secondary);
  border-radius: 12px;
  padding: 2rem;
}

.stat-section h3 {
  margin-bottom: 2rem;
  color: var(--text-color);
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  justify-items: center;
}

.stat-circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: var(--background-color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.stat-circle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 4px solid #4a90e2;
  opacity: 0.2;
}


.stat-percentage {
  font-size: 1.2rem;
  color: #4a90e2;
  margin-bottom: 0.5rem;
  min-height: 1.5rem; /* Maintain consistent height even when empty */
}

.stat-percentage.positive {
  color: #4CAF50;
}

.stat-percentage.negative {
  color: #f44336;
}

.stat-main-value {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  min-height: 2.5rem; /* Maintain consistent height */
}


/* Placeholder style for no data */
.stat-main-value:empty::before {
  content: '0';
  opacity: 0.5;
}

.stat-circle-container {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg); /* Start from top */
}


.stat-main-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.2rem;
}


.dashboard {
  padding: 2rem;
}

.dashboard h2 {
  margin-bottom: 2rem;
  color: white;
}


.stat-card:hover {
  transform: translateY(-5px);
  background-color: #333333;
}

.stat-card h3 {
  color: #808080; /* Grey color for the title */
  font-size: 1rem;
  margin: 0 0 1rem 0;
}

.stat-card .stat-value {
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
}

/* Performance Section Styling */
.performance-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
  width: 100%; /* Take full width */
}

.performance-header {
  margin-bottom: 2.5rem;
}

.performance-header h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.performance-filters {
  display: flex;
  justify-content: flex-start; /* Align filters to the left */
}

.performance-filters .flex {
  display: flex;
  gap: 1rem;
  width: auto; /* Let it take natural width */
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); /* Equal width columns with minimum 0 */
  gap: 4rem; /* Increase gap between circles */
  padding: 0 4rem; /* Add padding to prevent touching edges */
}

.stat-circle-container {
  width: 180px;
  height: 180px;
  margin: 0 auto; /* Center in column */
}

.progress-ring {
  width: 180px;
  height: 180px;
}

/* Filter select styling */
.filter-select {
  min-width: 150px;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .overview-cards {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .overview-container {
    padding: 1rem;
  }
  
  .stat-card {
    padding: 1rem;
  }

  .dashboard {
    padding: 1rem;
  }
  
  .stat-card .stat-value {
    font-size: 2rem;
  }
}

/* Dark mode specific styles if needed */
.dark-mode .overview-container {
  background-color: #1a1a1a;
}

.dark-mode .stat-card {
  background-color: #2a2a2a;
}

.dark-mode .stat-card:hover {
  background-color: #333333;
}

.detailed-stats {
  margin-top: 2rem;
}

.stat-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
}

.stat-section h3 {
  margin-bottom: 2rem;
  color: #fff;
  font-size: 1.2rem;
}

.circular-stats-grid {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 800px; /* Limit width to keep stats together */
}

.stat-circle-container {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}


.stat-content {
  position: relative;
  text-align: center;
  z-index: 1;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.2rem;
}

.stat-previous {
  font-size: 0.8rem;
  color: #4a90e2;
  opacity: 0.8;
}

.stat-label {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  margin-top: 0.3rem;
}

/* Optional: Add color variations based on comparison */
.progress-ring-circle.increase {
  stroke: #4CAF50; /* Green for increase */
}

.progress-ring-circle.decrease {
  stroke: #f44336; /* Red for decrease */
}

/* Animation for ring */
@keyframes progress {
  from {
    stroke-dashoffset: var(--circumference);
  }
  to {
    stroke-dashoffset: var(--target-offset);
  }
}

.progress-ring-circle {
  animation: progress 1s ease-out forwards;
}

.performance-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filter-select {
  appearance: none;
  background-color: #2a2a2a;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.6rem 2.5rem 0.6rem 1rem;
  transition: all 0.2s ease;
  position: relative;
  min-width: 150px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%234a90e2' viewBox='0 0 16 16'%3E%3Cpath d='M8 10.5l-4-4h8l-4 4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 16px;
}

.filter-select:hover {
  background-color: #333333;
  border-color: #4a90e2;
}

.filter-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.filter-select option {
  background-color: #2a2a2a;
  color: #fff;
  padding: 10px;
}

/* Add a wrapper to control the select appearance */
.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper::after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  pointer-events: none;
}

/* Responsive design */
@media (max-width: 768px) {
  .performance-filters {
    flex-direction: column;
    width: 100%;
  }

  .filter-select {
    width: 100%;
    min-width: unset;
  }
}

/* Overview Cards Styling - Smaller size */
.overview-container {
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  width: 100%; /* Add this */
}

.overview-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Change from 3 to 5 columns */
  gap: 1.5rem;
  width: 100%; /* Add this */
}

.stat-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%; /* Add this */
}

.stat-card h3 {
  color: #808080;
  font-size: 0.9rem;
  margin: 0 0 0.8rem 0;
}

.stat-card .stat-value {
  color: white;
  font-size: 2rem; /* Reduced from 2.5rem */
  font-weight: bold;
}

/* Performance Section Styling - Larger size */
.performance-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
  max-width: 1200px; /* Limit maximum width */
}

.performance-header {
  margin-bottom: 2.5rem; /* Increased spacing */
}

.performance-header h3 {
  font-size: 1.4rem; /* Increased size */
  margin-bottom: 1.5rem;
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem; /* Increased gap */
  padding: 1rem;
  justify-items: center;
}

.stat-circle-container {
  width: 140px; /* Increased from 120px */
  height: 140px; /* Increased from 120px */
}

/* Update StatCircle component size */
.progress-ring {
  width: 140px; /* Match container size */
  height: 140px; /* Match container size */
}

.stat-content .stat-value {
  font-size: 1.8rem; /* Increased from 1.5rem */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .overview-container,
  .performance-section {
    margin: 1rem;
  }
}

@media (max-width: 768px) {
  .overview-cards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .circular-stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 1.5rem;
  }
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  padding: 2rem;
  justify-items: center;
}

.stat-circle-container {
  width: 180px;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  transform: rotate(-90deg); /* Fixed transform property */
}

.stat-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.stat-value {
  font-size: 2.2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.2rem;
  text-align: center;
}

.stat-previous {
  font-size: 1rem;
  color: #4a90e2;
  opacity: 0.8;
  margin-bottom: 0.2rem;
  text-align: center;
}

.stat-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
  text-align: center;
}



/* Responsive styles */
@media (max-width: 1200px) {
  .circular-stats-grid {
    gap: 2rem;
  }
}

@media (max-width: 992px) {
  .circular-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .stat-circle-container {
    width: 160px;
    height: 160px;
  }

  .progress-ring {
    width: 160px;
    height: 160px;
  }

  

  .stat-value {
    font-size: 2rem;
  }
}

.performance-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.performance-header {
  margin-bottom: 2.5rem;
  padding-left: 1rem; /* Add padding to align with circles */
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 180px); /* Fixed width columns */
  gap: 3rem;
  padding: 0 1rem; /* Add left padding to align with header */
  justify-content: start; /* Align to the start instead of center */
}

/* Update responsive design */
@media (max-width: 1200px) {
  .circular-stats-grid {
    gap: 2rem;
  }
}

@media (max-width: 992px) {
  .circular-stats-grid {
    grid-template-columns: repeat(2, 180px);
    gap: 2rem 3rem; /* Vertical gap 2rem, Horizontal gap 3rem */
  }
}

@media (max-width: 576px) {
  .circular-stats-grid {
    grid-template-columns: 180px;
    padding: 0;
    justify-content: center; /* Center on mobile */
  }
  
  .performance-header {
    padding-left: 0;
  }
}

/* Dashboard specific styles */
.dashboard {
  padding: 2rem;
  background-color: #f0f0f0;
  width: 100%; /* Add this */
}

.dark-mode .dashboard {
  background-color: #1a1a1a;
}

/* Overview Cards */
.overview-container {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  max-width: 1200px;
}

.dark-mode .overview-container {
  background-color: #1a1a1a;
}

.overview-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.stat-card {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.dark-mode .stat-card {
  background-color: #2a2a2a;
}

.stat-card h3 {
  color: #666;
  font-size: 0.9rem;
  margin: 0 0 0.8rem 0;
}

.dark-mode .stat-card h3 {
  color: #808080;
}

.stat-card .stat-value {
  color: #333;
  font-size: 2rem;
  font-weight: bold;
}

.dark-mode .stat-card .stat-value {
  color: white;
}

/* Performance Section */
.performance-section {
  background: #e8e8e8;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.dark-mode .performance-section {
  background: #1a1a1a;
}

.performance-header h3 {
  color: #333;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.dark-mode .performance-header h3 {
  color: white;
}

/* StatCircle Component */
.progress-ring circle:first-child {
  stroke: #d1d1d1;
}

.dark-mode .progress-ring circle:first-child {
  stroke: #1a1a1a;
}

.progress-ring-circle {
  stroke: #4a90e2;
}

.stat-content .stat-value {
  color: #333;
}

.dark-mode .stat-content .stat-value {
  color: white;
}

.stat-previous {
  color: #666;
}

.dark-mode .stat-previous {
  color: #4a90e2;
}

.stat-label {
  color: #666;
}

.dark-mode .stat-label {
  color: #808080;
}

/* Filter Selects */
.filter-select {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
}

.dark-mode .filter-select {
  background-color: #2a2a2a;
  border: 1px solid #404040;
  color: #fff;
}

.filter-select:hover {
  background-color: #e8e8e8;
}

.dark-mode .filter-select:hover {
  background-color: #333333;
}

/* Overview Cards */
.overview-container {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  max-width: 1200px;
}

.dark-mode .overview-container {
  background-color: #1a1a1a;
}

.overview-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.stat-card {
  background-color: #ffffff; /* Lighter background for light mode */
  border-radius: 8px;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dark-mode .stat-card {
  background-color: #2a2a2a;
}

/* Hover states for light and dark mode */
.stat-card:hover {
  transform: translateY(-5px);
  background-color: #f5f5f5; /* Light grey for light mode hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .stat-card:hover {
  background-color: #333333; /* Darker color for dark mode hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.stat-card h3 {
  color: #666;
  font-size: 0.9rem;
  margin: 0 0 0.8rem 0;
}

.dark-mode .stat-card h3 {
  color: #808080;
}

.stat-card .stat-value {
  color: #333;
  font-size: 2rem;
  font-weight: bold;
}

.dark-mode .stat-card .stat-value {
  color: white;
}

/* Dashboard specific styles */
.dashboard {
  padding: 2rem;
  background-color: #f0f0f0;
}

.dark-mode .dashboard {
  background-color: #1a1a1a;
}

/* Performance Section */
.performance-section {
  background: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dark-mode .performance-section {
  background: #1a1a1a;
  box-shadow: none;
}

/* Update square card styles to match */
.square-card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.square-card:hover {
  background-color: #f5f5f5;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .square-card {
  background-color: #2c2c2c;
}

.dark-mode .square-card:hover {
  background-color: #3c3c3c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.settings-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.settings-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.settings-card {
  background-color: var(--background-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dark-mode .settings-card {
  background-color: #1e1e1e;
  border-color: rgba(255, 255, 255, 0.1);
}

.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.settings-icon {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  color: #4a90e2;
}

.settings-header h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-color);
}

.settings-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.settings-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.settings-info h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: var(--text-color);
}

.settings-info p {
  color: #666;
  font-size: 0.9rem;
}

.dark-mode .settings-info p {
  color: #999;
}

.settings-button {
  padding: 0.5rem 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.settings-button:hover {
  background-color: #357abd;
}

.api-key-input {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.api-key-input input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.dark-mode .api-key-input input {
  border-color: #444;
  background-color: #2d2d2d;
}

/* Switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4a90e2;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Select styling */
.settings-select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  min-width: 120px;
}

.dark-mode .settings-select {
  border-color: #444;
  background-color: #2d2d2d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .settings-container {
    padding: 1rem;
  }

  .settings-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .settings-button {
    width: 100%;
  }

  .api-key-input {
    flex-direction: column;
  }
}

/* Dark mode specific adjustments */
.dark-mode .settings-button {
  background-color: #4a90e2;
}

.dark-mode .settings-button:hover {
  background-color: #357abd;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.p-12 {
  padding: 3rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-purple-600 {
  color: rgb(147 51 234);
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar-menu-item.active {
  background-color: #8957e5;
  color: white;
}

.sidebar-menu-item:hover {
  background-color: #8957e5;
  opacity: 0.9;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 16px;
  gap: 8px;
  margin-left: 0;
}

.sidebar-title {
  margin: 0;
  white-space: nowrap;
}

/* You might also need to check the padding on your sidebar-menu class */
.sidebar-menu {
  padding-left: 16px;  /* Match the header's left padding */
}

/* App.css or similar global CSS file */
.app-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.sidebar {
  width: 250px;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.main-content {
  flex: 1;
  margin-left: 250px; /* Match sidebar width */
  width: calc(100% - 250px); /* Important: set width to remaining space */
  overflow-x: auto;
}

/* Update for collapsed sidebar */
.sidebar.collapsed {
  width: 50px;
}

.sidebar.collapsed + .main-content {
  margin-left: 50px;
  width: calc(100% - 50px);
}

/* Remove or update these conflicting styles */
.main-content {
  padding: 0; /* Remove default padding */
  transition: margin-left 0.3s ease;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .main-content {
    margin-left: 200px;
    width: calc(100% - 200px);
  }

  .sidebar.collapsed + .main-content {
    margin-left: 50px;
    width: calc(100% - 50px);
  }
}

/* Add these responsive styles */
.a2a-notifications {
  width: 100%;
  overflow-x: hidden;
}

.notifications-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* Responsive column widths */
@media screen and (max-width: 1400px) {
  .filter-bar {
    flex-wrap: wrap;
    gap: 8px;
  }

  .filter-bar input,
  .filter-bar select {
    min-width: calc(33.333% - 8px) !important; /* 3 items per row */
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 1024px) {
  .filter-bar input,
  .filter-bar select {
    min-width: calc(50% - 8px) !important; /* 2 items per row */
  }
}

@media screen and (max-width: 640px) {
  .filter-bar input,
  .filter-bar select {
    min-width: 100% !important; /* 1 item per row */
  }

  .notifications-container {
    margin: 0 -16px; /* Negative margin to allow full-width scrolling */
  }

  /* Adjust padding for mobile */
  td.px-6,
  th.px-6 {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Improve table readability on smaller screens */
.notifications-container table {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .notifications-container table {
    font-size: 13px;
  }
}

/* Ensure action buttons stay usable */
.flex.items-center.gap-3 {
  white-space: nowrap;
  min-width: fit-content;
}

/* Improve scrollbar appearance */
.notifications-container::-webkit-scrollbar {
  height: 8px;
}

.notifications-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notifications-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.notifications-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add or update these styles for responsive table */
.notifications-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.notifications-container table {
  min-width: 1200px; /* Minimum width to ensure readability */
  width: 100%;
}

/* Column width adjustments */
.notifications-container th,
.notifications-container td {
  padding: 12px 8px;
  font-size: 14px;
}

/* Responsive adjustments for different screen sizes */
@media screen and (max-width: 1600px) {
  .notifications-container table {
    font-size: 13px;
  }
  
  /* Adjust column widths for this breakpoint */
  td[class*="w-["], th[class*="w-["] {
    width: auto !important;
  }

  .notifications-container td:nth-child(3) { /* Title column */
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 1200px) {
  .notifications-container table {
    font-size: 12px;
  }

  .notifications-container th,
  .notifications-container td {
    padding: 8px 6px;
  }

  /* Further reduce title column width */
  .notifications-container td:nth-child(3) {
    max-width: 150px;
  }
}

/* Ensure the table scrolls horizontally on small screens */
@media screen and (max-width: 1024px) {
  .notifications-container {
    margin: 0 -8px;
  }

  .notifications-container table {
    margin: 0;
    padding: 0;
  }

  /* Keep action buttons visible and usable */
  .notifications-container td:last-child {
    position: sticky;
    right: 0;
    background: white; /* Match your table background */
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  }

  /* Adjust the action buttons container */
  .flex.items-center.gap-3 {
    gap: 8px;
  }
}

/* Dark mode adjustments */
.dark-mode .notifications-container td:last-child {
  background: #2c2c2c; /* Match your dark mode table background */
}

/* Improve table scrollbar */
.notifications-container::-webkit-scrollbar {
  height: 8px;
}

.notifications-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notifications-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.notifications-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark mode scrollbar */
.dark-mode .notifications-container::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.dark-mode .notifications-container::-webkit-scrollbar-thumb {
  background: #666;
}

.dark-mode .notifications-container::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Add these new styles at the end of App.css */

.dashboard-layout {
  width: 100%;
  padding: 0;
  margin: 0;
}

.dashboard-layout .overview-container {
  width: 100%;
  padding: 0 2rem; /* Match the performance section padding */
  margin: 0 0 1.5rem 0;
  background: none;
  max-width: none;
}

.dashboard-layout .overview-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  width: 100%;
}

.dashboard-layout .stat-card {
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  margin: 0;
  background-color: #f5f5f5; /* Very light grey background */
}

.dashboard-layout .performance-section {
  width: fit-content; /* Make container fit the content */
  margin: 0 2rem;
  padding: 2rem;
  background: #f5f5f5;
}

/* Update hover state */
.dashboard-layout .stat-card:hover {
  background-color: #ebebeb; /* Slightly darker on hover */
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

/* Update text colors for better contrast with light background */
.dashboard-layout .stat-card h3 {
  color: #666666; /* Darker grey for heading for better readability */
}

.dashboard-layout .stat-card .stat-value {
  color: #333333; /* Dark grey for the value */
}

/* Update performance section text colors */
.dashboard-layout .performance-section h3 {
  color: #333333; /* Dark grey for heading */
}

/* Update the circular stats grid */
.dashboard-layout .circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 180px); /* Fixed width for circles */
  gap: 2rem; /* Reduced gap between circles */
  width: fit-content;
}

/* Add padding to the performance header */
.dashboard-layout .performance-header {
  margin-bottom: 2rem;
  width: 100%;
}

/* Update filters container */
.dashboard-layout .performance-filters {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

/* Make select inputs more compact */
.dashboard-layout .filter-select {
  min-width: 120px;
  padding: 0.5rem 1rem;
}

/* Update the performance section styles */
.dashboard-layout .performance-section {
  width: fit-content;
  margin: 0 2rem; /* Keep left margin */
  padding: 2rem;
  background: #f5f5f5;
}

/* Center the contents */
.dashboard-layout .performance-header {
  margin-bottom: 2rem;
  width: 100%;
  text-align: center; /* Center the header text */
}

/* Center the filters */
.dashboard-layout .performance-filters {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center; /* Center the filters */
}

/* Center the circular stats grid */
.dashboard-layout .circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 180px);
  gap: 2rem;
  width: fit-content;
  margin: 0 auto; /* Center the grid */
}

/* Make select inputs more compact but centered */
.dashboard-layout .filter-select {
  min-width: 120px;
  padding: 0.5rem 1rem;
  text-align: center;
}

/* Update these styles in the performance section */
.performance-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.performance-header {
  margin-bottom: 2.5rem;
  text-align: left; /* Align header to the left */
}

.performance-header h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.performance-filters {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-start; /* Align filters to the left */
}

.circular-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Equal width columns */
  gap: 2rem;
  padding: 0 2rem; /* Add padding to prevent touching edges */
  width: 100%; /* Take full width */
}

.stat-circle-container {
  width: 180px;
  height: 180px;
  margin: 0 auto; /* Center circles in their columns */
}

/* Ensure consistent sizing for progress rings */
.progress-ring {
  width: 180px;
  height: 180px;
}

.performance-section {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
}

.performance-header {
  margin-bottom: 2rem;
}

.performance-header h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: left;
}

/* Filter container */
.filter-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

/* Filter select styling */
.filter-select {
  min-width: 150px;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #f0f0f0 !important; /* Light grey background */
  color: #333 !important; /* Dark text for contrast */
  border: 1px solid #ddd;
}

/* Dark mode filter select */
.dark-mode .filter-select {
  background-color: #2d2d2d !important;
  color: #fff !important;
  border-color: #404040;
}

/* Stats container */
.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-top: 2rem;
  gap: 1rem; /* Smaller gap between circles */
}

.stat-circle-container {
  width: 180px;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
}

.stat-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.stat-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Performance cards container */
.performance-cards-container {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.performance-cards-container > div {
  flex: 1;
}

/* Stats container */
.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 2rem;
  gap: 2rem;
}

/* Circle container */
.stat-circle-container {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Progress ring */
.progress-ring {
  position: absolute;
  top: 0;
  left: 0;
}

/* Content inside circle */
.stat-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Add this after your existing media queries */

@media screen and (max-width: 1798px) {
  .overview-cards {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  .circular-stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }

  .performance-section {
    width: 100%;
    max-width: none;
  }

  .dashboard-layout .overview-container {
    padding: 1rem;
  }

  .stat-card {
    min-width: 0;
  }
}

/* Ensure fluid layout above 1798px */
@media screen and (min-width: 1799px) {
  .overview-cards {
    grid-template-columns: repeat(5, 1fr);
  }

  .circular-stats-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Global responsive container */
.main-content {
  padding: 1.5rem;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

/* Responsive grid layouts */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

/* Form elements */
.filter-bar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.filter-bar input,
.filter-bar select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
}

/* Card layouts */
.card {
  background: var(--background-color-secondary);
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 100%;
}

/* Table responsiveness */
.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-container table {
  min-width: 100%;
}

/* Responsive breakpoints */
@media screen and (max-width: 1798px) {
  .main-content {
    padding: 1rem;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .card {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .filter-bar {
    grid-template-columns: 1fr;
  }

  .main-content {
    margin-left: 60px;
    width: calc(100% - 60px);
  }

  .sidebar.collapsed + .main-content {
    margin-left: 0;
    width: 100%;
  }
}

/* Dark mode support */
.dark-mode .card {
  background-color: #2a2a2a;
}

.dark-mode .filter-bar input,
.dark-mode .filter-bar select {
  background-color: #2a2a2a;
  border-color: #404040;
  color: white;
}

/* Ensure fluid layout above 1798px */
@media screen and (min-width: 1799px) {
  .main-content {
    padding: 2rem;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
}

/* Component-specific responsive styles */
.database-view,
.notifications-view,
.price-monitor,
.storefront-hunter,
.settings {
  max-width: 1920px;
  margin: 0 auto;
  padding: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .database-view,
  .notifications-view,
  .price-monitor,
  .storefront-hunter,
  .settings {
    padding: 1rem;
  }
}

/* Modal responsiveness */
.modal-content {
  width: 90%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
}

@media screen and (max-width: 640px) {
  .modal-content {
    width: 95%;
    padding: 1rem;
    margin: 1rem auto;
  }
}

/* Button and form element consistency */
button,
input,
select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

@media screen and (min-width: 640px) {
  button,
  input,
  select {
    width: auto;
  }
}

/* Ensure sidebar responsiveness */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .sidebar:not(.collapsed) {
    width: 200px;
  }

  .sidebar.collapsed + .main-content {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}

/* Add these styles at the end of App.css */

/* Dashboard responsive layout */
@media screen and (max-width: 1200px) {
  .dashboard-layout {
    padding: 1rem;
  }

  .dashboard-layout .overview-container {
    padding: 0 1rem;
  }

  .dashboard-layout .performance-cards-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .dashboard-layout .performance-section {
    margin: 0;
    width: 100%;
  }

  .dashboard-layout .performance-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .dashboard-layout .filter-container {
    width: 100%;
    flex-direction: column;
  }

  .dashboard-layout .filter-select {
    width: 100%;
    min-width: 100%;
  }

  .dashboard-layout .stats-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  .dashboard-layout .stat-circle-container {
    width: 160px;
    height: 160px;
  }

  .dashboard-layout .progress-ring {
    width: 160px;
    height: 160px;
  }

  .dashboard-layout .stat-content .stat-value {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-layout .overview-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-layout .stat-circle-container {
    width: 140px;
    height: 140px;
  }

  .dashboard-layout .progress-ring {
    width: 140px;
    height: 140px;
  }

  .dashboard-layout .stat-content .stat-value {
    font-size: 1.2rem;
  }

  .dashboard-layout .stat-content .stat-label {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-layout .overview-cards {
    grid-template-columns: 1fr;
  }

  .dashboard-layout .stat-card {
    height: auto;
    padding: 1rem;
  }

  .dashboard-layout .performance-section {
    padding: 1rem;
  }

  .dashboard-layout .circular-stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .dashboard-layout .stat-circle-container {
    width: 120px;
    height: 120px;
  }

  .dashboard-layout .progress-ring {
    width: 120px;
    height: 120px;
  }

  .dashboard-layout .stat-content .stat-value {
    font-size: 1rem;
  }
}

/* Improve dark mode contrast on smaller screens */
.dark-mode .dashboard-layout .stat-card {
  background-color: #2a2a2a;
}

.dark-mode .dashboard-layout .performance-section {
  background-color: #1a1a1a;
}

/* Ensure proper spacing in filter container */
.dashboard-layout .filter-container {
  gap: 0.5rem;
}

/* Improve touch targets on mobile */
@media (hover: none) and (pointer: coarse) {
  .dashboard-layout .stat-card,
  .dashboard-layout .filter-select {
    min-height: 44px; /* Minimum touch target size */
  }
}

/* Add these styles at the end of App.css */

/* A2A Performance card mobile optimization */
@media screen and (max-width: 768px) {
  .dashboard-layout .stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0;
    width: 100%;
  }

  .dashboard-layout .performance-section {
    padding: 1rem;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .dashboard-layout .stat-circle-container {
    margin: 0 auto;
    width: 130px; /* Slightly smaller on mobile */
    height: 130px;
  }

  .dashboard-layout .progress-ring {
    width: 130px;
    height: 130px;
  }

  /* Adjust the content inside circles */
  .dashboard-layout .stat-content {
    padding: 0.5rem;
  }

  .dashboard-layout .stat-content .stat-value {
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
  }

  .dashboard-layout .stat-content .stat-label {
    font-size: 0.65rem;
  }
}

/* Even smaller screens */
@media screen and (max-width: 480px) {
  .dashboard-layout .stats-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .dashboard-layout .stat-circle-container {
    width: 150px; /* Slightly larger when stacked */
    height: 150px;
  }

  .dashboard-layout .progress-ring {
    width: 150px;
    height: 150px;
  }

  .dashboard-layout .stat-content .stat-value {
    font-size: 1.3rem;
  }

  .dashboard-layout .stat-content .stat-label {
    font-size: 0.75rem;
  }

  /* Improve filter controls on mobile */
  .dashboard-layout .filter-container {
    padding: 0.5rem;
  }

  .dashboard-layout .filter-select {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }
}

/* Add or update these styles in App.css */

/* Adjust main content width when sidebar is collapsed */
.sidebar.collapsed + .main-content {
  margin-left: 50px;
  width: calc(100% - 50px);
  transition: margin-left 0.3s ease, width 0.3s ease;
}

/* Performance cards container responsive layout */
.performance-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  transition: grid-template-columns 0.3s ease;
}

/* Stack cards on laptop screens and below */
@media screen and (max-width: 1366px) {
  .performance-cards-container {
    grid-template-columns: 1fr;
  }

  /* Adjust the width of performance sections */
  .dashboard-layout .performance-section {
    width: 100%;
    margin: 0;
  }

  /* Ensure the graph maintains a good height */
  .dashboard-layout .performance-section:last-child .mt-4 {
    height: 250px !important;
  }
}

/* Further adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar.collapsed + .main-content {
    margin-left: 0;
    width: 100%;
  }

  .performance-cards-container {
    gap: 1rem;
  }

  .dashboard-layout .performance-section {
    padding: 1rem;
  }

  /* Adjust graph height for mobile */
  .dashboard-layout .performance-section:last-child .mt-4 {
    height: 200px !important;
  }
}

/* Ensure smooth transitions */
.main-content,
.performance-cards-container,
.performance-section {
  transition: all 0.3s ease;
}

/* Database view action icons styling */
.notifications-container td .flex.items-center {
  display: flex;
  align-items: center;
  gap: 8px;  /* Reduced from 12px */
}

.notifications-container td .flex.items-center a,
.notifications-container td .flex.items-center button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;  /* Reduced from 32px */
  height: 24px;  /* Reduced from 32px */
  padding: 4px;  /* Reduced from 6px */
  border-radius: 4px;
  background: transparent;  /* Removed background */
  transition: all 0.2s ease;
}

.notifications-container td .flex.items-center a:hover,
.notifications-container td .flex.items-center button:hover {
  background-color: rgba(0, 0, 0, 0.05);  /* Subtle hover effect */
  transform: translateY(-1px);  /* Reduced movement */
}

.dark-mode .notifications-container td .flex.items-center a:hover,
.dark-mode .notifications-container td .flex.items-center button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.notifications-container td .flex.items-center svg {
  width: 16px;  /* Reduced from 20px */
  height: 16px;  /* Reduced from 20px */
  color: #666;
}

.dark-mode .notifications-container td .flex.items-center svg {
  color: #999;
}
